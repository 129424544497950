import React from 'react';
import '../CSS/HeroSection.css';

const HeroSection = () => {
  return (
    <div className="hero-container">
      {/* Only the background image will be shown */}
    </div>
  );
};

export default HeroSection;
